"use client";

import { clsx } from "clsx";
import { FooterButtonLink } from "@/components/Footer/components/Link/FooterButtonLink.component";
import { Link } from "@/components/Link/Link.component";
import { archivePageLink, sitemapPageLink } from "@/components/page-links.constants";
import { useSite } from "@/contexts/site/site.context";
import { useSiteConfiguration } from "@/contexts/site-configuration/site-configuration.context";
import { inlineJsx } from "@/ts/locale/inline-jsx";
import { LinkButton } from "../Button/components/LinkButton/LinkButton.component";
import { Divider } from "../Divider/Divider.component";
import { FrontPageLink } from "../FrontPageLink/FrontPageLink.component";
import { GridItem } from "../Grid/components/GridItem.component";
import { Grid } from "../Grid/Grid.component";
import { PressEthicsIcon } from "../Icons/components/PressEthicsIcon/PressEthicsIcon.component";
import { SiteLogo } from "../SiteLogo/SiteLogo.component";
import { Translation } from "../Translation/Translation.component";
import { FooterColumnItem } from "./components/Column/FooterColumnItem.component";
import { Contact } from "./components/Contact/Contact.component";
import { Publisher } from "./components/EditorialStaff/Publisher.component";
import { FooterLinkList } from "./components/Link/FooterLinkList.component";
import type { FooterModel } from "./footer-model.types";
type FooterProps = {
  readonly className?: string;
  readonly model: FooterModel;
};
export function Footer({
  className,
  model
}: FooterProps) {
  const {
    locale,
    name: siteName,
    urls: siteUrls,
    id: siteId
  } = useSite();
  const {
    features: {
      isAggregateSite
    }
  } = useSiteConfiguration();
  const {
    address,
    sales,
    sections,
    editorialStaff,
    privacyPolicyUrl,
    publisher,
    imprint,
    supportLink,
    hasBrandDomain: isBrandSite
  } = model;
  const {
    editorInChief,
    editor
  } = editorialStaff;
  const isEditorialStaffShown = siteUrls.editorialStaff !== null;
  const pressEthicsLink = <Link className={clsx("footer-link", "!p-0")} href="https://www.nored.no/Etikk/Vaer-Varsom-plakaten" rel="noreferrer" openInNewTab>
      Vær Varsom-plakatens regler
    </Link>;
  const pressEthicsIcon = <Link className="mt-2 flex justify-center lg:justify-start" href="https://www.nored.no/Redaktoeransvar/Redaktoerplakaten" rel="noreferrer" openInNewTab>
      <PressEthicsIcon className="h-10 w-10" />
    </Link>;
  return <footer aria-labelledby="footer-heading" className={clsx(className, "bg-slate px-3 text-white")} data-sentry-component="Footer" data-sentry-source-file="Footer.component.tsx">
      <h2 className="sr-only" id="footer-heading">
        {{
        da: "Kolofon",
        de: "Impressum",
        en: "Colophon",
        no: "Kolofon",
        sv: "Kolofon"
      }[locale]}
      </h2>
      <div className="container flex flex-col items-center lg:items-start" data-k5a-pos="footer">
        <div className="flex w-full flex-col items-stretch divide-y divide-solid divide-white py-5 lg:flex-row lg:items-center lg:justify-between lg:divide-y-0 lg:border-b lg:border-solid lg:border-white">
          <FrontPageLink position="logo" data-sentry-element="FrontPageLink" data-sentry-source-file="Footer.component.tsx">
            <span className="sr-only">{siteName}</span>
            <SiteLogo className="mx-auto mb-3 w-full lg:mb-0 lg:h-6 lg:w-auto" contextId="footer" primaryClassName="fill-current" secondaryClassName="fill-current" siteId={siteId} size="md" data-sentry-element="SiteLogo" data-sentry-source-file="Footer.component.tsx" />
          </FrontPageLink>
          <div className="pt-5 lg:pt-0">
            <LinkButton className="w-full" href={archivePageLink} position="search" data-sentry-element="LinkButton" data-sentry-source-file="Footer.component.tsx">
              {{
              da: "Søg",
              de: "Suchen",
              en: "Search",
              no: "Søk",
              sv: "Sök"
            }[locale]}
            </LinkButton>
          </div>
        </div>
        <Grid className="gap-y-0 divide-y divide-solid divide-white pb-6 text-center lg:divide-y-0 lg:pt-4 lg:text-left" data-sentry-element="Grid" data-sentry-source-file="Footer.component.tsx">
          <GridItem className="space-y-6 pb-6 lg:py-0" columnSpan="1/4" data-sentry-element="GridItem" data-sentry-source-file="Footer.component.tsx">
            <FooterColumnItem heading={isAggregateSite ? null : {
            da: "Sektioner",
            de: "Themen",
            en: "Sections",
            no: "Seksjoner",
            sv: "Sektioner"
          }[locale]} data-sentry-element="FooterColumnItem" data-sentry-source-file="Footer.component.tsx">
              <FooterLinkList data-sentry-element="FooterLinkList" data-sentry-source-file="Footer.component.tsx">
                {sections.map(({
                key,
                name,
                url
              }, index) => <li key={key}>
                    <Link className="footer-link" href={url} openInNewTab="determineFromSource" position={`section-${index + 1}`}>
                      {name}
                    </Link>
                  </li>)}
                <li>
                  {/* TODO (JPW-17924): create sitemap for new design */}
                  <a className="footer-link" href={sitemapPageLink}>
                    {{
                    da: "Sitemap",
                    de: "Sitemap",
                    en: "Sitemap",
                    no: "Sitemap",
                    sv: "Sitemap"
                  }[locale]}
                  </a>
                </li>
                <li>
                  <Link className={clsx("footer-link", "mt-3")} href={siteUrls.rss} position="rss" data-sentry-element="Link" data-sentry-source-file="Footer.component.tsx">
                    {{
                    da: "RSS feeds",
                    de: "RSS-Feeds",
                    en: "RSS feeds",
                    no: "RSS feeds",
                    sv: "RSS-flöden"
                  }[locale]}
                  </Link>
                </li>
              </FooterLinkList>
            </FooterColumnItem>
            <Divider isLight data-sentry-element="Divider" data-sentry-source-file="Footer.component.tsx" />
          </GridItem>
          <GridItem className="space-y-6 py-6 lg:py-0" columnSpan="1/4" data-sentry-element="GridItem" data-sentry-source-file="Footer.component.tsx">
            {editor !== null ? <FooterColumnItem heading={editor.title}>
                <Contact model={editor.contact} />
                <FooterLinkList className="space-y-2">
                  {isEditorialStaffShown ? <li>
                      <Link className="footer-link" href={siteUrls.editorialStaff} openInNewTab="determineFromSource">
                        {{
                    da: "Redaktion",
                    de: "Redaktion",
                    en: "Editorial",
                    no: "Redaksjon",
                    sv: "Redaktionen"
                  }[locale]}
                      </Link>
                    </li> : null}
                  {isAggregateSite ? null : <li>
                      {/* TODO (JPW-18322): create landing pages for new design */}
                      <Link className="footer-link" href={siteUrls.aboutUs} openInNewTab="determineFromSource" position="about">
                        {{
                    da: `Om ${siteName}`,
                    de: `Über ${siteName}`,
                    en: `About ${siteName}`,
                    no: `Om ${siteName}`,
                    sv: `Om ${siteName}`
                  }[locale]}
                      </Link>
                    </li>}
                </FooterLinkList>
              </FooterColumnItem> : null}
            {editorInChief !== null ? <FooterColumnItem heading={editorInChief.title}>
                <Contact model={editorInChief.contact} />
              </FooterColumnItem> : null}
            <Publisher name={publisher.name} url={publisher.url} data-sentry-element="Publisher" data-sentry-source-file="Footer.component.tsx" />
            <p className="empty:hidden">
              {isBrandSite ? {
              da: "",
              de: "",
              en: "",
              no: inlineJsx`${siteName} arbeider etter ${pressEthicsLink} for god presseskikk. ${pressEthicsIcon}`,
              sv: ""
            }[locale] : {
              da: "",
              de: "",
              en: "",
              no: inlineJsx`${siteName} er en del av Watch Media. ${siteName} arbeider etter ${pressEthicsLink} for god presseskikk. ${pressEthicsIcon}`,
              sv: ""
            }[locale]}
            </p>
            {/* TODO (JPW-17895): Add a section about Watchmedier*/}
            <Divider isLight data-sentry-element="Divider" data-sentry-source-file="Footer.component.tsx" />
          </GridItem>
          <GridItem className="space-y-6 py-6 lg:py-0" columnSpan="1/4" data-sentry-element="GridItem" data-sentry-source-file="Footer.component.tsx">
            <FooterColumnItem heading={{
            da: "Bannerannoncering",
            de: "Vertrieb und Anzeigen",
            en: "Banner Advertising",
            no: "Bannerannonse",
            sv: "Bannerannonsering"
          }[locale]} data-sentry-element="FooterColumnItem" data-sentry-source-file="Footer.component.tsx">
              <Contact model={sales.ads.contact} data-sentry-element="Contact" data-sentry-source-file="Footer.component.tsx" />
              <Link className="footer-link" href={sales.ads.url} openInNewTab="determineFromSource" position="advertising" data-sentry-element="Link" data-sentry-source-file="Footer.component.tsx">
                {{
                da: "Annoncering",
                de: "Anzeigen",
                en: "Advertising",
                no: "Annonsering",
                sv: "Annonsering"
              }[locale]}
              </Link>
            </FooterColumnItem>
            <FooterColumnItem heading={{
            da: "Jobannoncering",
            de: "Stellenanzeigen",
            en: "Job Advertising",
            no: "Stillingsannonser",
            sv: "Jobbannonsering"
          }[locale]} data-sentry-element="FooterColumnItem" data-sentry-source-file="Footer.component.tsx">
              <Contact model={sales.jobAds.contact} data-sentry-element="Contact" data-sentry-source-file="Footer.component.tsx" />
              <Link className="footer-link" href={sales.jobAds.url} openInNewTab="determineFromSource" position="job-ads" data-sentry-element="Link" data-sentry-source-file="Footer.component.tsx">
                {{
                da: "Job",
                de: "Stellenanzeigen",
                en: "Jobs",
                no: "Stillinger",
                sv: "Jobb"
              }[locale]}
              </Link>
            </FooterColumnItem>
            {supportLink !== null ? <FooterColumnItem heading={{
            da: "Kontakt",
            de: "Kontakt",
            en: "Contact",
            no: "Kontakt",
            sv: "Kontakt"
          }[locale]}>
                <Link className="footer-link" href={supportLink} openInNewTab="determineFromSource" position="contact">
                  <Translation da="Kontakt kundeservice" de="Kundenservice kontaktieren" en="Contact customer service" no="Kontakt kundeservice" sv="Kontakta kundtjänst" />
                </Link>
              </FooterColumnItem> : null}
            <Divider isLight data-sentry-element="Divider" data-sentry-source-file="Footer.component.tsx" />
          </GridItem>
          <GridItem className="space-y-6 pt-6 lg:py-0" columnSpan="1/4" data-sentry-element="GridItem" data-sentry-source-file="Footer.component.tsx">
            <FooterColumnItem heading={{
            da: "Abonnement",
            de: "Abonnement",
            en: "Subscription",
            no: "Abonnement",
            sv: "Prenumeration"
          }[locale]} data-sentry-element="FooterColumnItem" data-sentry-source-file="Footer.component.tsx">
              <p>
                {{
                da: `Prøv ${siteName} gratis eller få tilbud på et abonnement, der passer til lige netop dig eller din virksomhed.`,
                de: `Melden Sie sich für ein kostenfreies Probeabonnement an oder testen Sie unverbindlich unsere Team- sowie unternehmensweiten Lösungen.`,
                en: `Try ${siteName} or get an offer for a subscription meeting the exact needs of you or your company.`,
                no: `Prøv ${siteName} gratis eller få tilbud på et abonnement tilpasset deg eller din virksomhet.`,
                sv: `Prova ${siteName} gratis eller få ett erbjudande om en prenumeration som passar dig eller ditt företag.`
              }[locale]}
              </p>
              <Contact model={sales.subscriptions.contact} data-sentry-element="Contact" data-sentry-source-file="Footer.component.tsx" />
              <Link className="footer-link" href={sales.subscriptions.url} openInNewTab="determineFromSource" position="subscription" data-sentry-element="Link" data-sentry-source-file="Footer.component.tsx">
                {{
                da: "Læs mere om abonnement her",
                de: "Erfahren Sie hier mehr über unser Abonnenment",
                en: "Learn more about subscriptions here",
                no: "Les mer om abonnementet her",
                sv: "Läs mer om prenumerationen här"
              }[locale]}
              </Link>
            </FooterColumnItem>
            <FooterColumnItem heading={{
            da: "Adresse",
            de: "Adresse",
            en: "Address",
            no: "Adresse",
            sv: "Adress"
          }[locale]} data-sentry-element="FooterColumnItem" data-sentry-source-file="Footer.component.tsx">
              <Contact model={address} data-sentry-element="Contact" data-sentry-source-file="Footer.component.tsx" />
            </FooterColumnItem>
            <FooterColumnItem heading={{
            da: "Retningslinjer",
            de: "Richtlinien",
            en: "Guidelines",
            no: "Retningslinjer",
            sv: "Riktlinjer"
          }[locale]} data-sentry-element="FooterColumnItem" data-sentry-source-file="Footer.component.tsx">
              <FooterLinkList data-sentry-element="FooterLinkList" data-sentry-source-file="Footer.component.tsx">
                <li>
                  <Link className="footer-link" href={privacyPolicyUrl} position="privacy-policy" data-sentry-element="Link" data-sentry-source-file="Footer.component.tsx">
                    {{
                    da: "Persondatapolitik",
                    de: "Datenschutz",
                    en: "Privacy Policy",
                    no: "Personvernerklæring",
                    sv: "Integritetspolicy"
                  }[locale]}
                  </Link>
                </li>
                {imprint !== null ? <li>
                    <Link className="footer-link" href={imprint.url} position="imprint">
                      {imprint.displayName}
                    </Link>
                  </li> : null}
                <li>
                  <FooterButtonLink onClick={() => {
                  /* eslint-disable no-undef -- Cookiebot is injected by an external script. */
                  if (typeof Cookiebot !== "undefined") {
                    Cookiebot.show();
                  }
                  /* eslint-enable no-undef */
                }} data-sentry-element="FooterButtonLink" data-sentry-source-file="Footer.component.tsx">
                    {{
                    da: "Administrér samtykke",
                    de: "Cookie-Einstellungen anpassen",
                    en: "Manage consent",
                    no: "Administrér samtykke",
                    sv: "Hantera samtycke"
                  }[locale]}
                  </FooterButtonLink>
                </li>
              </FooterLinkList>
            </FooterColumnItem>
          </GridItem>
        </Grid>
        <p className="w-full border-t border-solid border-white py-4 text-center">
          <span>Copyright &copy; {siteName} &mdash;</span>{" "}
          {{
          da: "Alt materiale på denne side er omfattet af gældende lov om ophavsret",
          de: "Alle Rechte vorbehalten",
          en: "All rights reserved",
          no: "Alt materiale på denne siden er omfattet av gjeldende lov om opphavsrett",
          sv: "Allt material på denna sida omfattas av gällande lag om upphovsrätt"
        }[locale]}
        </p>
      </div>
    </footer>;
}