import { Link } from "@/components/Link/Link.component";
import { Translation } from "@/components/Translation/Translation.component";
type WatchBrandLinkProps = {
  readonly targetUrl: string | null;
};
export function WatchBrandLink({
  targetUrl
}: WatchBrandLinkProps) {
  const brandName = <Translation da="Watch Medier" de="Watch Media" en="Watch Media" no="Watch Media" sv="Watch Media" />;
  return targetUrl !== null ? <Link className="font-theme-bold hover:underline" href={targetUrl} position="brand-link" rel="noreferrer" openInNewTab>
      {brandName}
    </Link> : <span className="font-bold">{brandName}</span>;
}