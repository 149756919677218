"use client";

import type { Dispatch, SetStateAction } from "react";
import { useSiteConfiguration } from "@/contexts/site-configuration/site-configuration.context";
import { useUser } from "@/contexts/user/user.context";
import { ToolbarMenuButtonLatestNews } from "./components/ToolbarMenuButtonLatestNews.component";
import { ToolbarMenuButtonLogIn } from "./components/ToolbarMenuButtonLogIn.component";
import { ToolbarMenuButtonLogOut } from "./components/ToolbarMenuButtonLogOut.component";
import { ToolbarMenuButtonSearch } from "./components/ToolbarMenuButtonSearch.component";
import { ToolbarMenuButtonToggleMenu } from "./components/ToolbarMenuButtonToggleMenu.component";
import { ToolbarMenuList } from "./components/ToolbarMenuList.component";
import { ToolbarMenuListItem } from "./components/ToolbarMenuListItem.component";
type ToolbarMenuDenseProps = {
  readonly className?: string;
  readonly hasLatestNews: boolean;
  readonly isNavOpen: boolean;
  readonly onToggle: Dispatch<SetStateAction<boolean>>;
  readonly theme?: "light" | "dark";
};
export function ToolbarMenuDense({
  className,
  hasLatestNews = true,
  isNavOpen = false,
  theme = "dark",
  onToggle
}: ToolbarMenuDenseProps) {
  const {
    isLoggedIn,
    urls: {
      loginAndReturnUrl,
      logoutAndReturnUrl
    }
  } = useUser();
  const {
    features: {
      isAggregateSite: isLoginLess
    }
  } = useSiteConfiguration();
  return <nav className={className} data-sentry-component="ToolbarMenuDense" data-sentry-source-file="ToolbarMenuDense.component.tsx">
      <ToolbarMenuList data-sentry-element="ToolbarMenuList" data-sentry-source-file="ToolbarMenuDense.component.tsx">
        {hasLatestNews ? <ToolbarMenuListItem theme={theme}>
            <ToolbarMenuButtonLatestNews />
          </ToolbarMenuListItem> : null}
        <ToolbarMenuListItem theme={theme} data-sentry-element="ToolbarMenuListItem" data-sentry-source-file="ToolbarMenuDense.component.tsx">
          <ToolbarMenuButtonSearch data-sentry-element="ToolbarMenuButtonSearch" data-sentry-source-file="ToolbarMenuDense.component.tsx" />
        </ToolbarMenuListItem>
        {isLoggedIn !== null && !isLoginLess ? <ToolbarMenuListItem theme={theme}>
            {isLoggedIn ? <ToolbarMenuButtonLogOut logOutUrl={logoutAndReturnUrl} /> : <ToolbarMenuButtonLogIn logInUrl={loginAndReturnUrl} />}
          </ToolbarMenuListItem> : null}
        <ToolbarMenuListItem theme={theme} data-sentry-element="ToolbarMenuListItem" data-sentry-source-file="ToolbarMenuDense.component.tsx">
          <ToolbarMenuButtonToggleMenu isNavOpen={isNavOpen} onToggle={onToggle} data-sentry-element="ToolbarMenuButtonToggleMenu" data-sentry-source-file="ToolbarMenuDense.component.tsx" />
        </ToolbarMenuListItem>
      </ToolbarMenuList>
    </nav>;
}