import clsx from "clsx";
import { Translation } from "@/components/Translation/Translation.component";
import { inlineJsx } from "@/ts/locale/inline-jsx";
import { MonitorBrandLink } from "./components/MonitorBrandLink/MonitorBrandLink.component";
import { WatchBrandLink } from "./components/WatchBrandLink/WatchBrandLink.component";
type ImprintProps = {
  readonly watchBrandUrl: string;
  readonly className?: string;
};
export function Imprint({
  watchBrandUrl,
  className
}: ImprintProps) {
  const brandLink = watchBrandUrl.includes("monitormedier.dk") ? <MonitorBrandLink targetUrl={watchBrandUrl} /> : <WatchBrandLink targetUrl={watchBrandUrl} />;
  return <p className={clsx("hidden select-none overflow-hidden text-xs uppercase text-inherit lg:block lg:h-2", className)} data-sentry-component="Imprint" data-sentry-source-file="Imprint.component.tsx">
      <Translation da={inlineJsx`En del af ${brandLink}`} de={inlineJsx`FinanzBusiness ist Teil der ${brandLink}`} en={inlineJsx`A part of ${brandLink}`} no={inlineJsx`En del av ${brandLink}`} sv={inlineJsx`En del av ${brandLink}`} data-sentry-element="Translation" data-sentry-source-file="Imprint.component.tsx" />
    </p>;
}