import { OutlineSearchIcon } from "@/components/Icons/components/HeroIcons/OutlineSearchIcon.component";
import { Link } from "@/components/Link/Link.component";
import { archivePageLink } from "@/components/page-links.constants";
import { toolbarMenuItemClassNames } from "../toolbar-menu-item-class-names";
import { ToolbarMenuDenseItem } from "./ToolbarMenuDenseItem.component";
export function ToolbarMenuButtonSearch() {
  return <Link className={toolbarMenuItemClassNames} href={archivePageLink} position="search" data-sentry-element="Link" data-sentry-component="ToolbarMenuButtonSearch" data-sentry-source-file="ToolbarMenuButtonSearch.component.tsx">
      <ToolbarMenuDenseItem label={{
      da: "Søg",
      de: "Suchen",
      en: "Search",
      no: "Søk",
      sv: "Sök"
    }} data-sentry-element="ToolbarMenuDenseItem" data-sentry-source-file="ToolbarMenuButtonSearch.component.tsx">
        <OutlineSearchIcon className="h-3 w-3" data-sentry-element="OutlineSearchIcon" data-sentry-source-file="ToolbarMenuButtonSearch.component.tsx" />
      </ToolbarMenuDenseItem>
    </Link>;
}