import type { ReactNode } from "react";
type EmailLinkProps = {
  readonly className?: string;
  readonly emailAddress: string;
  readonly children?: ReactNode;
};
export function EmailLink({
  className,
  emailAddress,
  children
}: EmailLinkProps) {
  return <a className={className} href={`mailto:${emailAddress}`} data-sentry-component="EmailLink" data-sentry-source-file="EmailLink.component.tsx">
      {children ?? emailAddress}
    </a>;
}