import type { FooterModel } from "../../footer-model.types";
import { ContactLine } from "../ContactLine/ContactLine.component";
type ContactProps = {
  readonly model: FooterModel.Contact;
};
export function Contact({
  model
}: ContactProps) {
  return <div className="flex flex-col items-center lg:items-start" data-sentry-component="Contact" data-sentry-source-file="Contact.component.tsx">
      {model.lines.map(line => <ContactLine key={line.key} line={line} />)}
    </div>;
}