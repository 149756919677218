import { clsx } from "clsx";
import type { ReactNode } from "react";

/* @ts-expect-error -- this will work */
const numberOfColumns = (Array.from({
  length: 12
}, (value, index) => index + 1) as const);
const columnClassNamesByEnum: Readonly<Record<ColumnSpan, string>> = {
  "1/2": "lg:col-span-6",
  "1/3": "lg:col-span-4",
  "1/4": "lg:col-span-3",
  "2/3": "lg:col-span-8",
  "3/4": "lg:col-span-9",
  full: "lg:col-span-full"
};
const columnClassNamesByNumber: Readonly<Record<ColumnRange, string>> = {
  1: "lg:col-span-1",
  6: "lg:col-span-6"
};
const columnStartClassNamesByNumber: Readonly<Record<ColumnRange, string>> = {
  1: "lg:col-start-1",
  2: "lg:col-start-2",
  6: "lg:col-start-6",
  8: "lg:col-start-8"
};
const columnEndClassNamesByNumber: Readonly<Record<ColumnRange, string>> = {
  12: "lg:col-end-12",
  6: "lg:col-end-6",
  8: "lg:col-end-8",
  9: "lg:col-end-9"
};
const rowSpanClassNamesByEnum: Readonly<Record<RowSpan, string>> = {
  "1/1": "lg:row-span-1",
  "2/1": "lg:row-span-2",
  "4/1": "lg:row-span-4"
};
type GridItemProps = {
  readonly children: ReactNode;
  readonly className?: string;
  readonly columnSpan?: ColumnSpan | ColumnRange;
  readonly rowSpan?: RowSpan | null;
  readonly to?: ColumnRange | null;
  readonly from?: ColumnRange | null;
  readonly position?: string;
};
type ColumnSpan = "1/2" | "1/3" | "1/4" | "2/3" | "3/4" | "full";
type RowSpan = "1/1" | "2/1" | "4/1";
type ColumnRange = (typeof numberOfColumns)[number];
export function GridItem({
  children,
  position,
  className,
  columnSpan,
  to = null,
  from = null,
  rowSpan = "1/1"
}: GridItemProps) {
  return <div className={clsx(className, typeof columnSpan === "string" && columnClassNamesByEnum[columnSpan], typeof columnSpan === "number" && columnClassNamesByNumber[columnSpan], rowSpan !== null && rowSpanClassNamesByEnum[rowSpan], from !== null && columnStartClassNamesByNumber[from], to !== null && columnEndClassNamesByNumber[to], "relative empty:hidden")} data-k5a-pos={position} data-sentry-component="GridItem" data-sentry-source-file="GridItem.component.tsx">
      {children}
    </div>;
}