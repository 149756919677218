import type { FooterModel } from "@/components/Footer/footer-model.types";
type TextContactLineProps = {
  readonly line: FooterModel.TextContactLine<string>;
};
export function TextContactLine({
  line: {
    value
  }
}: TextContactLineProps) {
  return <p className="break-all" data-sentry-component="TextContactLine" data-sentry-source-file="TextContactLine.component.tsx">{value}</p>;
}