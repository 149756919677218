"use client";

import { Link } from "@/components/Link/Link.component";
import { useSite } from "@/contexts/site/site.context";
import { FooterColumnItem } from "../Column/FooterColumnItem.component";
type PublisherProps = {
  readonly url: string;
  readonly name: string;
};
export function Publisher({
  url,
  name
}: PublisherProps) {
  const {
    locale
  } = useSite();
  return <FooterColumnItem heading={{
    da: "Udgiver",
    de: "Herausgeber",
    en: "Publisher",
    no: "Utgiver",
    sv: "Utgivare"
  }[locale]} data-sentry-element="FooterColumnItem" data-sentry-component="Publisher" data-sentry-source-file="Publisher.component.tsx">
      <Link className="footer-link" href={url} position="publisher" rel="noreferrer" openInNewTab data-sentry-element="Link" data-sentry-source-file="Publisher.component.tsx">
        {name}
      </Link>
    </FooterColumnItem>;
}