import type { ReactNode } from "react";
type FooterColumnItemProps = {
  readonly heading: string | null;
  readonly children: ReactNode;
};
export function FooterColumnItem({
  heading,
  children
}: FooterColumnItemProps) {
  return <div data-sentry-component="FooterColumnItem" data-sentry-source-file="FooterColumnItem.component.tsx">
      <h2 className="mb-1 font-theme-bold uppercase tracking-tight">
        {heading}
      </h2>
      <div className="space-y-2">{children}</div>
    </div>;
}