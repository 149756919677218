"use client";

import clsx from "clsx";
import { useState, type ReactNode } from "react";
import { AdSlot } from "@/components/AdSlot/AdSlot.component";
type StickyBannerProps = {
  readonly children: ReactNode;
};
export function StickyBanner({
  children
}: StickyBannerProps) {
  const [isStickyEmpty, setIsStickyEmpty] = useState<boolean>(true);
  return <div className={clsx("py-2 lg:py-4", isStickyEmpty ? "container px-0" : "banner-container")} data-sentry-component="StickyBanner" data-sentry-source-file="StickyBanner.component.tsx">
      {/* Main layout */}
      <div className="container 2xl:mx-0">{children}</div>
      <div className={clsx("sticky-banner sticky top-2.5 hidden self-start", !isStickyEmpty && "2xl:block")}>
        <AdSlot className="lg:h-[600px] lg:w-[160px]" domId="sticky" onBeforeShow={isEmpty => setIsStickyEmpty(isEmpty)} data-sentry-element="AdSlot" data-sentry-source-file="StickyBanner.component.tsx" />
      </div>
    </div>;
}