import { clsx } from "clsx";
import type { ReactNode } from "react";
import { Link } from "@/components/Link/Link.component";
type LinkButtonProps = {
  readonly className?: string;
  readonly openInNewTab?: boolean | "determineFromSource";
  readonly href: string;
  readonly children: ReactNode;
  readonly position?: string;
};
export function LinkButton({
  className,
  href,
  openInNewTab,
  children,
  position
}: LinkButtonProps) {
  return <Link className={clsx(className, "common-button", "hover-button")} href={href} openInNewTab={openInNewTab} position={position} data-sentry-element="Link" data-sentry-component="LinkButton" data-sentry-source-file="LinkButton.component.tsx">
      {children}
    </Link>;
}