import { OutlineClockIcon } from "@/components/Icons/components/HeroIcons/OutlineClockIcon.component";
import { Link } from "@/components/Link/Link.component";
import { latestPageLink } from "@/components/page-links.constants";
import { toolbarMenuItemClassNames } from "../toolbar-menu-item-class-names";
import { ToolbarMenuDenseItem } from "./ToolbarMenuDenseItem.component";
export function ToolbarMenuButtonLatestNews() {
  return <Link className={toolbarMenuItemClassNames} href={latestPageLink} data-sentry-element="Link" data-sentry-component="ToolbarMenuButtonLatestNews" data-sentry-source-file="ToolbarMenuButtonLatestNews.component.tsx">
      <ToolbarMenuDenseItem label={{
      da: "Seneste",
      de: "Aktuelle",
      en: "Latest",
      no: "Siste",
      sv: "Senaste"
    }} data-sentry-element="ToolbarMenuDenseItem" data-sentry-source-file="ToolbarMenuButtonLatestNews.component.tsx">
        <OutlineClockIcon className="h-3 w-3" data-sentry-element="OutlineClockIcon" data-sentry-source-file="ToolbarMenuButtonLatestNews.component.tsx" />
      </ToolbarMenuDenseItem>
    </Link>;
}