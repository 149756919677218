"use client";

import clsx from "clsx";
import Image from "next/image";
import { Link } from "@/components/Link/Link.component";
import { useSite } from "@/contexts/site/site.context";
import { useSiteConfiguration } from "@/contexts/site-configuration/site-configuration.context";
import { tailwindScreenSize } from "@/utilities/get-tailwind-config";
type TopAdFallbackProps = {
  readonly className?: string;
};
export function TopAdFallback({
  className
}: TopAdFallbackProps) {
  const {
    locale,
    urls
  } = useSite();
  const {
    topBannerFallback: {
      mobile,
      desktop
    }
  } = useSiteConfiguration();
  const label = {
    da: "Annonce",
    de: "Anzeige",
    en: "Advertisement",
    no: "Annonse",
    sv: "Annons"
  }[locale];
  if (mobile === null || desktop === null) return null;
  return <div className={clsx(className, "relative", "before:absolute before:-top-2 before:left-0 before:select-none before:text-xs before:uppercase before:leading-tight before:text-silver before:content-[attr(data-label)]")} data-label={label} data-sentry-component="TopAdFallback" data-sentry-source-file="TopAdFallback.component.tsx">
      <Link className="block" href={urls.subscription} data-sentry-element="Link" data-sentry-source-file="TopAdFallback.component.tsx">
        <picture className="relative block h-[160px] w-[320px] lg:h-[180px] lg:w-[930px]" data-sentry-element="picture" data-sentry-source-file="TopAdFallback.component.tsx">
          <source media={`(min-width: ${tailwindScreenSize.md})`} sizes="930px" srcSet={desktop} />
          <source media="(min-width: 0)" srcSet={mobile} />
          <Image alt={label} className="select-none object-cover" draggable={false} sizes="320px" src={mobile} fill priority data-sentry-element="Image" data-sentry-source-file="TopAdFallback.component.tsx" />
        </picture>
      </Link>
    </div>;
}