"use client";

import { useSite } from "@/contexts/site/site.context";
import type { FooterModel } from "../../footer-model.types";
type PhoneNumberContactLineProps = {
  readonly line: FooterModel.PhoneNumberContactLine<string>;
};
export function PhoneNumberContactLine({
  line: {
    phoneNumber
  }
}: PhoneNumberContactLineProps) {
  const {
    locale
  } = useSite();
  return <p className="break-all" data-sentry-component="PhoneNumberContactLine" data-sentry-source-file="PhoneNumberContactLine.component.tsx">
      {{
      da: "Tlf.:",
      de: "Tel.:",
      en: "Tel.:",
      no: "Tel.:",
      sv: "Tel.:"
    }[locale]}{" "}
      {phoneNumber}
    </p>;
}