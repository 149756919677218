"use client";

import { clsx } from "clsx";
import type { HeaderModel } from "@/components/Header/models/header-model.types";
import { Link } from "@/components/Link/Link.component";
type NavigationDrawerProps = {
  readonly className?: string;
  readonly menuItems: ReadonlyArray<HeaderModel.MenuItem>;
  readonly pathname: string;
};
export function NavigationDrawer({
  className,
  menuItems,
  pathname
}: NavigationDrawerProps) {
  const flattenedTopMenu = menuItems.flatMap((menuItem: HeaderModel.MenuItem) => {
    if (menuItem.children.length > 0) {
      return menuItem.children;
    }
    return menuItem;
  });
  return <nav className={clsx(className, "h-full")} data-sentry-component="NavigationDrawer" data-sentry-source-file="NavigationDrawer.component.tsx">
      <ul>
        {flattenedTopMenu.map(({
        key,
        name,
        url
      }, index) => {
        const isActive = pathname.includes(url);
        return <li className={clsx("h-6 justify-center border-b border-silver transition-colors", isActive ? "bg-primary text-white hover:bg-primary-hover" : "hover:text-primary")} data-k5a-pos={`mobile-nav-${index + 1}`} key={key}>
              <div className="container h-6">
                <Link className="flex h-full w-full items-center py-1 text-sm font-theme-bold uppercase" href={url} openInNewTab="determineFromSource">
                  {name}
                </Link>
              </div>
            </li>;
      })}
      </ul>
    </nav>;
}