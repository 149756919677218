import clsx from "clsx";
import type { ReactNode } from "react";
type FooterLinkListProps = {
  readonly children: ReactNode;
  readonly className?: string;
};
export function FooterLinkList({
  children,
  className
}: FooterLinkListProps) {
  return <ul className={clsx(className, "flex flex-col items-center lg:items-stretch")} data-sentry-component="FooterLinkList" data-sentry-source-file="FooterLinkList.component.tsx">
      {children}
    </ul>;
}