import type { ReactNode, MouseEventHandler } from "react";
type FooterLinkProps = {
  readonly onClick?: MouseEventHandler<HTMLButtonElement>;
  readonly children: ReactNode;
};
export function FooterButtonLink({
  onClick,
  children
}: FooterLinkProps) {
  return <button className="footer-link" type="button" onClick={onClick} data-sentry-component="FooterButtonLink" data-sentry-source-file="FooterButtonLink.component.tsx">
      {children}
    </button>;
}