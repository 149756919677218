"use client";

import { Link } from "@/components/Link/Link.component";
import { Translation } from "@/components/Translation/Translation.component";
import { useUser } from "@/contexts/user/user.context";
import { inlineJsx } from "@/ts/locale/inline-jsx";
type UserLabelProps = {
  readonly className?: string;
};
export function UserLabel({
  className
}: UserLabelProps) {
  const {
    model,
    urls
  } = useUser();
  if (model.user !== null) {
    const manageAccountLink = <Link className="font-theme-bold hover:underline" href={urls.manageAccount} rel="noreferrer" openInNewTab>
        {model.user.fullName}
      </Link>;
    return <p className={className}>
        <Translation da={inlineJsx`Du er logget ind som ${manageAccountLink}`} de={inlineJsx`Sie sind eingeloggt als ${manageAccountLink}`} en={inlineJsx`You are logged in as ${manageAccountLink}`} no={inlineJsx`Du er logget inn som ${manageAccountLink}`} sv={inlineJsx`Du är inloggad som ${manageAccountLink}`} />
      </p>;
  }
  if (model.access.$class === "ip") {
    const accessProvider = model.access.providedBy;
    return <p className={className}>
        <Translation da={`Du har adgang via ${accessProvider}`} de={`Sie haben Zugang über ${accessProvider}`} en={`You have access via ${accessProvider}`} no={`Du har tilgang via ${accessProvider}`} sv={`Du har åtkomst via ${accessProvider}`} />
      </p>;
  }
  return null;
}