import type { FooterModel } from "@/components/Footer/footer-model.types";
import { EmailAddressContactLine } from "./EmailAddressContactLine.component";
import { PhoneNumberContactLine } from "./PhoneNumberContactLine.component";
import { TextContactLine } from "./TextContactLine.component";
type ContactLineProps = {
  readonly line: FooterModel.ContactLine<string>;
};
export function ContactLine({
  line
}: ContactLineProps) {
  switch (line.$class) {
    case "email-address":
      return <EmailAddressContactLine line={line} />;
    case "phone-number":
      return <PhoneNumberContactLine line={line} />;
    case "text":
      return <TextContactLine line={line} />;
  }
}