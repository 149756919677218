import type { CommonHeroIconProps } from "./common-hero-icon-props.types";

/**
 * The outline 24x24 'arrow-left-on-rectangle' icon from [HeroIcons 2.0](https://heroicons.com).
 */
export function OutlineArrowLeftOnRectangleIcon({
  className
}: CommonHeroIconProps) {
  return <svg className={className} fill="none" stroke="currentColor" strokeWidth={1} viewBox="0 0 24 24" data-sentry-element="svg" data-sentry-component="OutlineArrowLeftOnRectangleIcon" data-sentry-source-file="OutlineArrowLeftOnRectangleIcon.component.tsx">
      <path d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="OutlineArrowLeftOnRectangleIcon.component.tsx" />
    </svg>;
}