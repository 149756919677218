import { OutlineArrowRightOnRectangleIcon } from "@/components/Icons/components/HeroIcons/OutlineArrowRightOnRectangleIcon.component";
import { toolbarMenuItemClassNames } from "../toolbar-menu-item-class-names";
import { ToolbarMenuDenseItem } from "./ToolbarMenuDenseItem.component";
type ToolbarMenuButtonLogOutComponentProps = {
  readonly logOutUrl: string;
};
export function ToolbarMenuButtonLogOut({
  logOutUrl
}: ToolbarMenuButtonLogOutComponentProps) {
  return <a className={toolbarMenuItemClassNames} href={logOutUrl} data-sentry-component="ToolbarMenuButtonLogOut" data-sentry-source-file="ToolbarMenuButtonLogOut.component.tsx">
      <ToolbarMenuDenseItem label={{
      da: "Log ud",
      de: "Abmelden",
      en: "Log out",
      no: "Logg ut",
      sv: "Logga ut"
    }} data-sentry-element="ToolbarMenuDenseItem" data-sentry-source-file="ToolbarMenuButtonLogOut.component.tsx">
        <OutlineArrowRightOnRectangleIcon className="h-3 w-3" data-sentry-element="OutlineArrowRightOnRectangleIcon" data-sentry-source-file="ToolbarMenuButtonLogOut.component.tsx" />
      </ToolbarMenuDenseItem>
    </a>;
}