import { OutlineArrowLeftOnRectangleIcon } from "@/components/Icons/components/HeroIcons/OutlineArrowLeftOnRectangleIcon.component";
import { toolbarMenuItemClassNames } from "../toolbar-menu-item-class-names";
import { ToolbarMenuDenseItem } from "./ToolbarMenuDenseItem.component";
type ToolbarMenuButtonLogInComponentProps = {
  readonly logInUrl: string;
};
export function ToolbarMenuButtonLogIn({
  logInUrl
}: ToolbarMenuButtonLogInComponentProps) {
  return <a className={toolbarMenuItemClassNames} href={logInUrl} data-sentry-component="ToolbarMenuButtonLogIn" data-sentry-source-file="ToolbarMenuButtonLogIn.component.tsx">
      <ToolbarMenuDenseItem label={{
      da: "Log ind",
      de: "Anmelden",
      en: "Log in",
      no: "Logg inn",
      sv: "Logga in"
    }} data-sentry-element="ToolbarMenuDenseItem" data-sentry-source-file="ToolbarMenuButtonLogIn.component.tsx">
        <OutlineArrowLeftOnRectangleIcon className="h-3 w-3" data-sentry-element="OutlineArrowLeftOnRectangleIcon" data-sentry-source-file="ToolbarMenuButtonLogIn.component.tsx" />
      </ToolbarMenuDenseItem>
    </a>;
}