import { EmailLink } from "@/components/EmailLink/EmailLink.component";
import type { FooterModel } from "../../footer-model.types";
type EmailAddressContactLineProps = {
  readonly line: FooterModel.EmailAddressContactLine<string>;
};
export function EmailAddressContactLine({
  line: {
    emailAddress
  }
}: EmailAddressContactLineProps) {
  return <EmailLink className="footer-link" emailAddress={emailAddress} data-sentry-element="EmailLink" data-sentry-component="EmailAddressContactLine" data-sentry-source-file="EmailAddressContactLine.component.tsx" />;
}